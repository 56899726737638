import {
  mapFirstTeacher,
  mapTeachers
} from "../../../core/mappers/map-teachers"
import { mapYoutubeId } from "../../../core/mappers/map-youtube-id"
import { mapTopics, TopicNode } from "../../../core/mappers/map-topics"
import { QuestionAnswered } from "../question-answered-types"
import {
  QuestionAnsweredPageQuery,
  ContentfulTeacher
} from "../../../../graphql/types"
import { readTime, truncate } from "../../../core/utils"
import { mapMetadata } from "../../../core/mappers/map-metadata"

export const mapSourceQuestionAnsweredToView = (
  query: QuestionAnsweredPageQuery
): QuestionAnswered => {
  const details = query.contentfulQa!

  const topics = mapTopics(
    query.allContentfulTopic.nodes as TopicNode[],
    details.primaryTopic! as TopicNode
  )

  const teachers = mapTeachers(details.teachers as Partial<ContentfulTeacher>[])
  const answer = details.answer?.childMarkdownRemark?.rawMarkdownBody || ""
  const attribution =
    details.attribution?.childMarkdownRemark?.rawMarkdownBody || ""

  const metadata = mapMetadata(
    details.metadata,
    {
      title: details.question,
      description: details.answer?.childMarkdownRemark?.plainText
        ? truncate(details.answer?.childMarkdownRemark?.plainText)
        : null
    },
    {
      title: details.pageTitle
    }
  )

  return {
    contentfulId: details.contentful_id,
    question: details.question!,
    answer,
    attribution,
    topics,
    primaryTopic: details.primaryTopic,
    youtubeId: mapYoutubeId(details.youtubeUrl),
    copyright:
      details.copyright?.copyrightText?.childMarkdownRemark?.rawMarkdownBody ||
      "",
    teachers,
    time: readTime(answer.replace(/<\/?[^>]+(>|$)/g, "")),
    metadata
  }
}
