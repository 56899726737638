import React, { FunctionComponent } from "react"

export interface AnswerSchemaProps {
  question: string
  answer: string
}

export const AnswerSchema: FunctionComponent<AnswerSchemaProps> = ({
  question,
  answer
}) => {
  const data = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: question,
        acceptedAnswer: {
          "@type": "Answer",
          text: answer
        }
      }
    ]
  }

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  )
}
