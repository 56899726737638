interface CalculateLineCount {
  lineCount: number
  containerHeight: number
}

export const calculateLineCount = (
  node: HTMLElement,
  maxLines?: number
): CalculateLineCount => {
  const parent = node.parentElement!
  const parentOffsetTop = parent.offsetTop || 0
  const elementOffsetTop = node.offsetTop
  const offsetTop = elementOffsetTop - parentOffsetTop
  const parentBoundingRect = parent.getBoundingClientRect()
  const parentStyle = getComputedStyle(parent)
  // NOTE: `offsetTop` differs depending on whether parent has position relative or not
  const parentRelative = parentStyle.position === "relative"
  const parentHeight = parentBoundingRect?.height || window.innerHeight
  const styles = getComputedStyle(node)

  const lineHeight = parseFloat(styles.lineHeight)

  const textContainerHeight =
    parentHeight - (parentRelative ? elementOffsetTop : offsetTop)
  let lineCount = Math.floor(textContainerHeight / lineHeight) || 1

  if (maxLines) {
    lineCount = Math.min(lineCount, maxLines)
  }

  const containerHeight = lineCount * lineHeight

  return { lineCount, containerHeight }
}
