import React, { FunctionComponent, useLayoutEffect, useRef } from "react"
import classNames from "classnames"
import { calculateLineCount } from "./utils"
import styles from "./multiline-text.module.scss"

export interface MultilineTextProps {
  className?: string
  maxLines?: number
  text: string
}

export const MultilineText: FunctionComponent<MultilineTextProps> = ({
  className,
  maxLines,
  text
}) => {
  const elementRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const node = elementRef.current!
    const { lineCount, containerHeight } = calculateLineCount(node, maxLines)

    node.style.webkitLineClamp = `${lineCount}`
    node.style.maxHeight = `${containerHeight}px`
  }, [maxLines, text])

  return (
    <div
      className={classNames(styles.multilineText, className)}
      ref={elementRef}
    >
      {text}
    </div>
  )
}
