import { useIntl } from "react-intl"
import React, { FunctionComponent, Fragment } from "react"
import { QuestionAnswered } from "../../question-answered-types"
import { RelatedProduct } from "../../../../types/related-product"
import {
  RelatedProducts,
  RelatedProductGroups
} from "../../../../components/related-products/related-products"
import { DetailsPageHeader } from "../../../../components/details-page-header/details-page-header"
import { AnswerDetails } from "../answer-details/answer-details"
import { AnswerSchema } from "../answer-schema/answer-schema"

export interface QuestionAnsweredDetailsPageProps {
  contentfulId?: string
  details: QuestionAnswered
  featuredProduct: RelatedProduct | null
  relatedProducts?: RelatedProductGroups | null
}

export const QuestionAnsweredDetailsPage: FunctionComponent<
  QuestionAnsweredDetailsPageProps
> = ({ contentfulId, details, featuredProduct, relatedProducts }) => {
  const { formatMessage } = useIntl()
  const { teachers } = details

  return (
    <Fragment>
      <DetailsPageHeader
        topics={details.topics}
        title={details.question}
        teachers={teachers}
        share
        id={contentfulId}
        contentType="qa"
      />
      <AnswerSchema question={details.question} answer={details.answer} />
      <AnswerDetails
        answer={details.answer}
        copyright={details.copyright}
        time={details.time}
        youtubeId={details.youtubeId}
        featuredProduct={featuredProduct}
        attribution={details.attribution}
      />
      {relatedProducts && (
        <RelatedProducts
          products={relatedProducts}
          renderLabel={(label) =>
            formatMessage({ id: `store-product-productType-${label}` })
          }
        />
      )}
      {/* <div className={recomStyles.relatedResourcesWrapper}>
        {teacher?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "teacher",
              slug: teacher.slug,
              idsToRemove: [details.contentfulId],
              itemId: details.contentfulId
            }}
            title={teacher.name || ""}
            overline="More from this teacher"
            body={teacher.shortBio || ""}
            link={`/${PathPrefix.Teachers}/${teacher.slug}`}
          />
        )}
        {primaryTopic?.slug && (
          <Recommendations
            recombeeConfig={{
              type: "topic",
              slug: primaryTopic.slug,
              idsToRemove: [details.contentfulId],
              itemId: details.contentfulId
            }}
            title={primaryTopic.name || ""}
            overline="More on this topic"
            link={`/${PathPrefix.Topics}/${primaryTopic.slug}`}
          />
        )}
      </div> */}
    </Fragment>
  )
}
