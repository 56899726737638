import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { navigate } from "gatsby"
import { RelatedProduct } from "../../../../types/related-product"
import { CardPromo } from "../../../../components/card/card-promo"
import styles from "./answer-details.module.scss"
import { Markdown } from "@components/markdown/markdown"
import { useShopifyProduct } from "@hooks/useShopifyProduct"

export interface AnswerDetailsProps {
  answer: string
  attribution: string | null
  youtubeId: string | null
  copyright?: string
  time: number
  featuredProduct: RelatedProduct | null
}

export const AnswerDetails: FunctionComponent<AnswerDetailsProps> = ({
  answer,
  attribution,
  copyright,
  time,
  youtubeId,
  featuredProduct
}) => {
  const { data } = useShopifyProduct(featuredProduct?.handle || "")

  const price = data?.price || ""
  const compareAtPrice = data?.compareAtPrice || ""

  return (
    <section className={styles.answerDetails}>
      <div className={styles.content}>
        <span className={styles.timeToRead}>
          <FormattedMessage id="general-reading-time" values={{ time }} />
        </span>
        {youtubeId && (
          <div className={styles.video}>
            <lite-youtube videoid={youtubeId}></lite-youtube>
          </div>
        )}
        <Markdown content={answer} className={styles.answer} />
        {attribution && (
          <>
            <hr className={styles.divider} />
            <Markdown content={attribution} className={styles.attribution} />
          </>
        )}
        {copyright && (
          <Markdown content={copyright} className={styles.copyright} />
        )}
        {featuredProduct && (
          <CardPromo
            className={styles.promo}
            author={featuredProduct.author ? featuredProduct.author.name : ""}
            authorLink={
              featuredProduct.author ? featuredProduct.author.link : ""
            }
            image={featuredProduct.image}
            price={price}
            compareAtPrice={compareAtPrice}
            body={featuredProduct.title}
            onClick={(): void => {
              navigate(featuredProduct.link)
            }}
          />
        )}
      </div>
    </section>
  )
}
