import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { MultilineText } from "../multiline-text/multiline-text"
import { Link } from "../link/link"
import { Button } from "../button/button"
import { Card } from "./card"
import styles from "./card-promo.module.scss"
import { showCompareAtPrice } from "@core/prices"
import { formatPrice } from "@utils/formatPrice"

export interface CardPromoProps {
  author: string
  authorLink?: string
  body: string
  compareAtPrice: string
  className?: string
  image: string
  onClick?: MouseEventHandler
  price: string
  title?: string
  buttonText?: string
  url?: string
}

export const CardPromo: FunctionComponent<CardPromoProps> = ({
  author,
  authorLink,
  body,
  buttonText,
  className,
  compareAtPrice,
  image,
  onClick,
  price,
  title = "Related resource",
  url
}) => (
  <Card
    background="smoke"
    className={classNames(styles.cardPromo, className)}
    onClick={onClick}
    variant="flat"
  >
    <p className={styles.title}>{title}</p>
    <img className={styles.image} src={image} alt="" />
    <div className={styles.textContent}>
      {authorLink ? (
        <Link to={authorLink} className={styles.author}>
          {author}
        </Link>
      ) : (
        <span className={styles.author}>{author}</span>
      )}
      <MultilineText className={styles.body} text={body} maxLines={2} />
    </div>
    <div className={styles.prices}>
      <span
        className={classNames(
          styles.originalPrice,
          compareAtPrice && styles.discounted
        )}
      >
        {formatPrice(price)}
      </span>

      {showCompareAtPrice(price, compareAtPrice) && (
        <span className={styles.discountPrice}>
          {formatPrice(compareAtPrice)}
        </span>
      )}
    </div>
    {url && (
      <Button variant="primary" size="sm" to={url} className={styles.button}>
        {buttonText}
      </Button>
    )}
  </Card>
)
