import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { QuestionAnsweredPageQuery } from "../../../../graphql/types"
import { PageProps } from "../../../types/page"
import { mapProductListToRelatedProductsView } from "../../store/mappers/related-products-mapper"
import { mapSourceQuestionAnsweredToView } from "../mappers/question-answered-mapper"
import { mapProductDetailsToRelatedProduct } from "../../store/mappers/store-common-mappers"
import { QuestionAnsweredDetailsPage } from "../components/question-answered-details-page/question-answered-details-page"
import { Page } from "../../../components/page/page"
import { mapVariantDetailsToView } from "../../store/mappers/product-details-mapper"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"

export const QuestionsAnsweredTemplate: FunctionComponent<
  PageProps<QuestionAnsweredPageQuery>
> = ({ data, ...props }) => {
  const { slug } = data?.contentfulQa || {}
  const hideMetaDescription =
    slug === "when-person-dies-where-does-his-or-her-spirit-and"
  const questionAnswered = mapSourceQuestionAnsweredToView(data!)
  const qAData = data?.contentfulQa
  const { metadata } = qAData || {}

  const metadataOverrides = {
    title: metadata?.title || questionAnswered.metadata.title,
    description: hideMetaDescription
      ? undefined
      : metadata?.description || questionAnswered.metadata.description,
    openGraphImage:
      metadata?.image?.fixed?.src || questionAnswered.metadata.openGraphImage
  }

  const relatedProducts = mapProductListToRelatedProductsView(
    data!.contentfulQa?.relatedProducts
  )

  const featuredProductNode = data?.contentfulQa?.featuredProduct
  const featuredProduct = featuredProductNode
    ? mapProductDetailsToRelatedProduct(
        mapVariantDetailsToView(featuredProductNode)
      )
    : null

  return (
    <Page
      {...props}
      metadata={{ ...questionAnswered.metadata, ...metadataOverrides }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.QuestionsAnswered,
          {
            label: questionAnswered.question,
            link: `${props.uri}`
          }
        ]
      }}
    >
      <QuestionAnsweredDetailsPage
        contentfulId={props.pageContext.contentfulId!}
        details={questionAnswered}
        relatedProducts={relatedProducts}
        featuredProduct={featuredProduct}
      />
    </Page>
  )
}

export default QuestionsAnsweredTemplate

export const query = graphql`
  query QuestionAnsweredPage($id: String, $topicCodes: [String]) {
    contentfulQa(id: { eq: $id }) {
      ...QuestionAnswered
      slug
    }
    allContentfulTopic(
      filter: { topicCode: { in: $topicCodes } }
      sort: { fields: topicCode }
    ) {
      ...TopicDetailsList
    }
  }
`
